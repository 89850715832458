<template>
  <div>
      <div class="p-4 pt-1" >
        <h4 class="title-dark">¿Cómo crecerá e impactará tu dinero con esta semilla?</h4>
        <div class="vx-row mt-4">
          <div class="vx-col w-full">
            <p>A continuación se presenta una simulación de tu inversión en el proyecto semilla de <span class="bold">{{currentProjectName}}</span>, por un monto de: </p>
            <vs-alert v-if="invalidAmount" color="danger" class="mt-3" style="height: auto">Por favor ingresa un valor
              entre <span class="bold">{{currentMinInvestment}}</span> y
              <span class="bold">{{currentMaxInvestment}}</span> en el espacio correspondiente.
            </vs-alert>
            <div class="flex items-center flex-grow justify-left mt-5">
              <vs-input
                class="investment-input"
                type="text"
                v-model.lazy="currentSimulationInvestment"
                v-money="money"
                name="newInvestment"
                v-validate="'required'"
                :min="1"
                autocomplete="off"
                :placeholder="currentProject.minPlaceholder" />
              <vs-button class="statement-btn ml-6" color="primary" @click="updateInvestmentSimulation" >Actualizar</vs-button>
            </div>

            <p class="mt-5 italic">
              Recuperarás el total del monto invertido al completar el
              <span class="bold">{{currentSimulationData.porcentaje}}%</span> del plazo que equivale a
              <span class="bold">{{currentSimulationData.meses}} meses</span>.
            </p>
              <br>
              <p v-if="currentSimulationData.delivery_term > 0">
                  Este proyecto cuenta con un periodo de entrega de {{currentSimulationData.delivery_term}} meses, durante los cuales recibirás los intereses proporcionales a tu inversión.
                  Para más información consulta nuestro
                  <a rel="noopener" href="https://blog.redgirasol.com/periodo-de-entrega/" target="_blank">blog aquí.</a>
              </p>
          </div>
        </div>

        <div class="vx-row mt-6">
          <div class="vx-col w-full">
            <vs-tabs v-model="activeTab" alignment="fixed" color="primary" class="simulation-tabs">
              <vs-tab label="Tu inversión">
                <div class="xxl:px-4 lg:px-2 px-1 pt-5">
                  <div class="simulation-data-container">
                    <!-- row 1 -->
                    <div></div>
                    <div class="simulation-data-title">Recuperación</div>
                    <div></div>
                    <div class="simulation-data-title">Intereses Netos</div>
                    <div></div>
                    <div class="simulation-data-title">Total</div>

                    <!-- row 2 -->
                    <div class="simulation-data-row">Pago Mensual</div>
                    <div class="simulation-info-section">{{ currentSimulationData.recuperacion }}</div>
                    <div class="simulation-sign">+</div>
                    <div class="simulation-info-section">{{ currentSimulationData.intereses }}</div>
                    <div class="simulation-sign">=</div>
                    <div class="simulation-info-section high">{{ currentSimulationData.pagoMensualTotal }}</div>

                    <!-- row 3 -->
                    <div class="simulation-data-row">Pago Total</div>
                    <div class="simulation-info-section">{{ currentSimulationData.recuperacionTotal }}</div>
                    <div class="simulation-sign">+</div>
                    <div class="simulation-info-section">{{ currentSimulationData.interesesTotal }}</div>
                    <div class="simulation-sign">=</div>
                    <div class="simulation-info-section filled">{{ currentSimulationData.pagoTotal }}</div>
                  </div>
                </div>
                  <p>Cifras mensuales promedio, mostradas para fines informativos</p>
              </vs-tab>

              <!--vs-tab label="El impacto ambiental" >
                <div class="xxl:px-4 lg:px-2 px-1 pt-5">
                  <div class="simulation-data-container">
                    <div></div>
                    <div class="simulation-data-title">Inversión</div>
                    <div></div>
                    <div class="simulation-data-title">Kilogramos de CO<sub>2</sub></div>
                    <div></div>
                    <div class="simulation-data-title">Total</div>

                    <div class="simulation-data-row">Impacto Mensual</div>
                    <div class="simulation-info-section">{{ currentSimulationData.recuperacion }}</div>
                    <div class="simulation-sign">x</div>
                    <div class="simulation-info-section">{{ currentSimulationData.intereses }}</div>
                    <div class="simulation-sign">=</div>
                    <div class="simulation-info-section high">{{ currentSimulationData.pagoMensualTotal }}</div>

                    <div class="simulation-data-row">Impacto Total</div>
                    <div class="simulation-info-section">{{ currentSimulationInvestment }}</div>
                    <div class="simulation-sign">x</div>
                    <div class="simulation-info-section">{{ currentSimulationData.interesesTotal }}</div>
                    <div class="simulation-sign">=</div>
                    <div class="simulation-info-section filled">{{ currentSimulationData.pagoTotal }}</div>
                  </div>
                </div>
              </vs-tab-->

              <vs-tab label="El plan de pagos" >
                <div class="px-1 pt-5">
                  <vs-table ref="paymentsPlanTable" :data="payments" :max-items="itemsPerPage" stripe pagination noDataText="No hay registros disponibles">
                    <!--div slot="header" class="ml-4 mb-6 flex items-center flex-grow justify-left" v-if="isMounted">
                      <span class="ml-2 mr-4">Mostrando {{ paymentsPlanCurrentPage * itemsPerPage - (itemsPerPage - 1) }} - {{ payments.length - paymentsPlanCurrentPage * itemsPerPage > 0 ? paymentsPlanCurrentPage * itemsPerPage : payments.length }} de <strong>{{ payments.length }}</strong> </span>
                    </div-->

                    <template slot="thead">
                      <vs-th class="bold" sort-key="number">No.</vs-th>
                      <vs-th class="bold">Día de Pago</vs-th>
                      <vs-th class="bold">Pago Mensual</vs-th>
                      <vs-th class="bold">Recuperación</vs-th>
                      <vs-th class="bold">Rendimiento<br>con IVA</vs-th>
                      <vs-th class="bold">Comisión<br>RedGirasol</vs-th>
                      <vs-th class="bold">Pago neto</vs-th>
                      <vs-th class="bold">Recuperación<br>acumulada</vs-th>
                      <vs-th class="bold">Rendimiento<br>acumulado</vs-th>
                      <vs-th class="bold">Pagos netos<br>acumulados</vs-th>
                    </template>
                    <template slot-scope="{data}">
                      <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                        <vs-td :data="data[indextr].number">
                          {{ tr.number }}
                        </vs-td>
                        <vs-td :data="data[indextr].date">
                          {{ humanDateFormat(tr.date) }}
                        </vs-td>
                        <vs-td :data="data[indextr].payment">
                          {{ tr.payment }}
                        </vs-td>
                        <vs-td :data="data[indextr].first">
                          {{ tr.first }}
                        </vs-td>
                        <vs-td :data="data[indextr].amorti">
                          {{ tr.amorti }}
                        </vs-td>
                        <vs-td :data="data[indextr].interest">
                          {{ tr.interest }}
                        </vs-td>
                        <vs-td :data="data[indextr].iva">
                          {{ tr.iva }}
                        </vs-td>
                        <vs-td :data="data[indextr].investor_comission">
                          {{ tr.investor_comission }}
                        </vs-td>
                        <vs-td :data="data[indextr].neto">
                          {{ tr.neto }}
                        </vs-td>
                        <vs-td :data="data[indextr].final">
                          {{ tr.final }}
                        </vs-td>
                      </vs-tr>
                    </template>
                  </vs-table>
                  <div class="vx-row mt-4">
                    <div class="vx-col lg:w-3/4 w-full">
                      Las fechas del plan de pagos durante la etapa de fondeo son estimados y podrían variar al iniciar
                      el proceso de instalación.
                    </div>
                  </div>
                </div>
              </vs-tab>
            </vs-tabs>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import {VMoney} from 'v-money'
import formatHelper from '@mixins/formatHelper';
import currencyHelper from '@mixins/currencyHelper';
import qualificationsHelper from '@mixins/qualificationsHelper';
import dateHelper from "@mixins/dateHelper";
export default {
  name: "InvestmentSimulation",
  mixins: [formatHelper, currencyHelper, qualificationsHelper, dateHelper],
  directives: {money: VMoney},
  props: {
    initialInvestment: {type: [String,Number], required: true},
    currentProject: {type:Object, required: true},
    currentProjectName: {type: String, required: true}
  },
  data (){
    return {
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '$',
        suffix: '',
        precision: 0,
        masked: false
      },
      payments: [],
      itemsPerPage: 5,
      currentSimulationInvestment: this.initialInvestment,
      currentSimulationData: {
        intereses: null,
        interesesTotal: null,
        meses: null,
        pagoMensualTotal: null,
        pagoTotal: null,
        porcentaje: null,
        recuperacion: null,
        recuperacionTotal: null,
      },

      impactSimulation: {
        monthKG: null,
        netKG: null,
        monthTons: null,
        totalTons: null,
      },

      isMounted: false,
      invalidAmount: false,
      activeTab: 0,
    }
  },
  async mounted(){
    this.showLoading(true)
    await this.getSimulation(this.initialInvestment);
    await this.getPaymentsInfo(this.initialInvestment);
    this.isMounted = true;
    this.showLoading(false)
  },
  computed: {
    currentMinInvestment(){
      return this.currentProject.minFormat;
    },
    currentMaxInvestment(){
      return this.currentProject.remainingFormat;
    },
  },
  methods: {
    async updateInvestmentSimulation(){
      this.invalidAmount = false;
      const updatedInv = this.unMoneyFormat(this.currentSimulationInvestment);
      const valid = this.validateInvestmentAmount(this.currentProject, updatedInv);
      if(!valid){
        this.invalidAmount = true;
        return;
      }
      this.$emit('on-simulation-updated', updatedInv);
      this.showLoading(true)
      await this.getSimulation(updatedInv);
      await this.getPaymentsInfo(updatedInv);
      this.showLoading(false)
    },
    async getSimulation(amount){
      const payload = { project_id: this.currentProject.id, investment: amount, user_id: this.UserId };
      try {
        const res = await axios.post('api/projects/simulationResume', payload);
        this.currentSimulationData = res.data;
      }
      catch (e) {
        this.warn(e)
      }
    },
    validateInvestmentAmount(proj, amount){
      if(amount === null){
        return false;
      }
      return amount >= proj.minimum && amount <= proj.remaining;
    },
    calcImpacts(){

    },
    async getPaymentsInfo(amount){
      const payload = { project_id: this.currentProject.id, investment: amount, user_id: this.UserId };
      try{
        const response = await axios.post(`api/projects/simulation`, payload);
        this.payments = response.data;
      }
      catch (e) {
      }
    },
    close(){
      this.$store.commit("TOGGLE_IS_SIMULATION_POPUP_ACTIVE", false);
    },
  }
}
</script>

<style >
.simulation-tabs .vs-tabs--li button {
  font-family: 'Gilroybold',sans-serif !important;
  font-weight: normal !important;
}

.simulation-data-container {
  display: grid;
  grid-template-columns: 0.5fr 1fr 0.1fr 1fr 0.1fr 1fr;
  column-gap: 1rem;
  row-gap: 0.85rem;
}

.simulation-data-title {
  padding-left: 0.85rem;
}

.simulation-data-row {
  text-align: right;
  align-self: center;
  margin-bottom: 0.9rem;
}

.simulation-info-section {
  border: 2px solid rgba(var(--vs-rgblack),1);
  background: transparent!important;
  color: rgba(var(--vs-rgblack),1);
  padding: .68rem 0.75rem;
  border-radius: 6px;
  margin-bottom: 0.9rem;
}

.simulation-info-section.filled {
  color: white !important;
  background-color: rgba(var(--vs-dark), 1) !important;
}

.simulation-info-section.high {
  color: rgba(var(--vs-primary),1);
  border: 2px solid rgba(var(--vs-primary),1);
}

.simulation-sign {
  text-align: center;
  align-self: center;
  margin-bottom: 0.9rem;
  font-family: 'Gilroybold',sans-serif;
}
</style>
