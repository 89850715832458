<template>
  <div>
    <!-- BANNER DE ABONO RECURRENTE -->
    <vx-card
      v-if="
        !investorPlan.autoinvest_deposits &&
        investorPlan.autoinvest_applicant_payments
      "
      class="mt-5 border-green"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
            <div class="vx-row" style="justify-content:center">
                <img :src="autoDepositsLogo" alt="user-img" width="80" />
            </div>
        </div>
        <div class="vx-col md:w-3/5 w-full">
            <h2 class="mt-2">Ahórrate la comisión por éxito</h2>
            <h5 class="mt-2">
                Activa el abono recurrente y te bonificamos el 2% de comisión.
            </h5>
        </div>
        <div class="vx-col md:w-1/5 w-full text-center">
            <vs-button
                class="mt-6"
                color="dark"
                @click.stop="goToInvestmentPlan"
                >Conocer más
            </vs-button>
        </div>
      </div>
    </vx-card>
    <!-- TERMINA BANNER DE ABONO RECURRENTE -->

    <!-- BANNER DE INVERSION AUTOMATICA -->
    <vx-card
      v-if="!investorPlan.autoinvest_applicant_payments"
      class="mt-5 border-green"
    >
      <div class="vx-row">
        <div class="vx-col md:w-1/5 w-full">
            <div class="vx-row" style="justify-content:center">
                <img :src="autoInvestmentLogo" alt="user-img" width="80" />
            </div>
        </div>
        <div class="vx-col md:w-3/5 w-full">
            <h2 class="mt-2">¿No sabes en cuál proyecto invertir?</h2>
            <h5 class="mt-2">
                Activa la inversión automática para diversificar tus
                inversiones.
            </h5>
        </div>
        <div class="vx-col md:w-1/5 w-full text-center">
            <vs-button
                class="mt-6"
                color="dark"
                @click.stop="goToInvestmentPlan"
                >Conocer más
            </vs-button>
        </div>
      </div>
    </vx-card>
    <!-- TERMINA BANNER DE INVERSION AUTOMATICA -->
  </div>
</template>
<script>
export default {
  name: "InvestorPlanBanner",
  data() {
    return {
      autoDepositsLogo: require('@assets/images/icons/auto-deposits-icon.png'),
      autoInvestmentLogo: require('@assets/images/icons/auto-investment-icon.png'),
      investorPlan: {
        autoinvest_applicant_payments: true,
        autoinvest_deposits: true
      }
    }
  },
  mounted() {},
  async beforeMount() {
    this.isMounted = false
    this.showLoading(true)
    this.getPlansInfo()
    this.isMounted = true
    this.showLoading(false)
  },
  methods: {
    async getPlansInfo() {
      try {
        const res = await axios.get(
          `api/v2/investor/${this.InvestorId}/getInvestorPlansInfo`
        )
        this.investorPlan = res.data.plan
      } catch (e) {}
    },
    async goToInvestmentPlan(){
      await this.$router.push({name: 'planesInversion'});
    },
  }
}
</script>

<style scoped>
.border-green {
  border-color: #188414;
  border-style: solid;
  border-width: 2px;
  border-radius: 10px;
}
</style>
